import React from 'react';
import principalImage from '../assets/images/AF-3.jpeg'; // Update the path to your actual image
import principalImage1 from '../assets/images/AF-4.jpeg';
import principalImage2 from '../assets/images/heljwq2a.png';

const PrincipalMessage = () => {
  return (
    <section className="principal-message-section">
    <h2>Project Description</h2>
      <div className="principal-message-container">
        <div className="principal-image">
          <img src={principalImage} alt="Principal" />
        </div>
        <div className="principal-message-content">
          <p>
            The project Adolescent Girls and Widows groups are poor and illiterate away from modern changes. There is a need to bring awareness and provide financial responsibilities making women become conversant with the accounts both profit and loss useful to maintain their family, with the training activities women role will be enhanced in family and community. Adolescent Girls and Widows in these poor families find difficult to manage families. She is deprived of nutritious food, health care and minimum education. Women are poor, illiterate, exploited and suffer from many problems, within and outside houses. 
          </p>
          <p>
            Even at home Adolescent Girls and Widows is feeble human being and her desire shall be to her husband. Man makes here to yield to his desire. She gives birth to his children, looks after the entire family affairs and helps husband in the double yoke. Slavery overburdens here physical strength and strain. She is treated as unpaid servant in the family. She doesn’t have any right on property and self-decision making in her socio-economic status in the family as well as society.
            Thus, a woman always depends upon the mercy of her husband and male dominated society. 
          </p>
        </div>
      <br />

      <div className="principal-message-content11">
        <h2> What Is The problem?</h2>
        <p>
          The Proposed Project will be implemented in 5 slum areas in Aurangabad District of Maharashtra. Slum dwellers are from remote villages migrated to Aurangabad Rural for want of work. Their villages are remotely situated on small hill ranges often affected by drought and famine. The target groups are poor and illiterate widows and women living in substandard slums on outskirts of Aurangabad surroundings thus despised and isolated from the Society. In these slums an average family has parents 2, grant parents 1 or 2, children about 5 members making a total of 8 to nine members. Mostly it is joint family under on tin roof in different cabin blocks system. Roof is either asbestos sheets, temporarily walls built with stones and mud, floor with gravel mud or stones, no bathrooms and lavatory facilities attached, as single bulb light is provided to their huts, no television, no radio or no newspapers for them. Assets seen for each family are fowls, cooking vessels, crowbar, pick axe and spade their labor work instruments. Rice and dhal curry is staple food for three times a day.
        </p>
      </div><br />

      <div className="principal-message-content1">
        <h2>Additional Information Of The Project</h2>
        <p>
          According to the lawful economic profile of Women in the World is as follows. Woman represent 50% population make up 30% of rural labor force, perform 60% of all working hours, receive 10% of the worked income but still own less than 1% of the WORLD PROPERTY. This is also true of Indian Woman and very true of rural women. In our country rural women suffers being both economically and socially invisible. Social invisibility is a result of general status of second class citizen, usually accorded to Women. It is not surprising that developmental programs which do not make into account the aspirations of women are not effective in achieving their objectives. The growing realization that rural Women are not inarticulate, illiterate, ignorant aspects of welfare but are in fact, productive, hard-working adults who have coped with battle for survival from a very young age, makes their participation in the development process increasingly necessary and imperative. This is the critical area of forming base and objective of the project.
        </p>
      </div><br />

      <div className="principal-message-content1">
        <h2>How Will This Project Solve The Problem?</h2>
        <p>
          Therefore, there is a need for Community Organising for Rural Upliftment Society to introduce skills training to rehabilitate suffering women’s economic status by organising and providing Stitching training and alternate employment opportunity for the empowerment of women in these villages. The proposed Stitching training is a great opportunity to the poor women both married and unmarried to earn their livelihood if they successfully complete the Training. There are 30 members in each Batch and total 360 women will be trained.
        </p>

        <div className="principal-images">
          <img src={principalImage1} alt="Principal" />
          <img src={principalImage2} alt="Principal" />
        </div>
      </div>
</div>
    </section>
  );
};

export default PrincipalMessage;
