import React from 'react';
import visionImage from '../assets/images/mission-vision-values-icon-set-modern-flat-icons-design_207371-1941.jpg';
import missionImage from '../assets/images/mission.png'; // Replace with the actual image name
import whatwedo from '../assets/images/todo.jpg';

const VisionMission = () => {
  return (
    <section className="vision-mission-section">
      <div className="vision-mission-container">
        {/* Vision Card */}
        <div className="vision-mission-card vision-card">
          <div className="card-image">
            <img src={visionImage} alt="Vision" />
          </div>
          <div className='card-content'>
            <h2>Vision</h2>
            <p>
              A world where every woman can freely use her voice, make her own decisions, and engage in economic opportunities. A society rooted in equality and empowerment. In this vision, women have access to quality education, healthcare, and career paths, enabling them to fully contribute to the economy and their communities.
            </p>
          </div>
        </div>

        {/* Mission Card */}
        <div className="vision-mission-card mission-card">
          <div className="card-image">
            <img src={missionImage} alt="Mission" />
          </div>
          <div className='card-content'>
            <h2>Mission</h2>
            <p>
              Our mission is to empower women across cultures and ethnicities to stand up for justice, equality and change in all facets of life and society, in both local and global contexts. We accomplish this through Advocacy, Training and Rehabilitation. We are dedicated to empowering women to achieve their full economic potential by inspiring both women and men to become advocates, change makers, and leaders in their community.
            </p>
          </div>
        </div>

        {/* What We Do */}
        <div className="vision-mission-card what-we-do-card">
          <div className="card-image">
            <img src={whatwedo} alt="What We Do" />
          </div>
          <div className='card-content'>
            <h2>What We Do?</h2>
            <ul>
              <li>ENLIGHTEN women to recognize their innate value, worth, and significance.</li>
              <li>ENCOURAGE women to live up to their full potential.</li>
              <li>EMPOWER women by creating channels of safety, growth & equality in society.</li>
              <li>EQUIP women with skills to live the change they hope to see.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
