import React from 'react';

import StudentA from '../assets/images/Icon.jpg';
import StudentB from '../assets/images/Icon1.jpeg';
import StudentC from '../assets/images/Icon.jpg';

const Testimonials = () => {
  return (
    <section className="testimonials" id="testimonials">
      <h2>GOVERNING BODIES</h2>
      <div className="testimonials-list">
        <div className="testimonial">
          <img src={StudentA} alt="Student A" className="testimonial-img" /><br /><br />
          <h4>Mr. Shyam Bhikaram Harne</h4>
          <p>PRESIDENT</p>
          
        </div>
        <div className="testimonial">
          <img src={StudentB} alt="Student B" className="testimonial-img" /><br /><br />
          <h4>Mrs. Shraddha Shyam Harne</h4>
          <p>SECRETARY</p>
          
        </div>
        <div className="testimonial">
          <img src={StudentC} alt="Student C" className="testimonial-img" /><br /><br />
          <h4>Mr. Bhikaram Devram Harne</h4>
          <p>TREASURER</p>
          
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
