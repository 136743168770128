import React from 'react';
import secretaryImage from '../assets/images/ai-img2.avif'; // Update the path to your actual image

const SecretaryMessage = () => {
  return (
    <section className="secretary-message-section">
        <h2>Potential Long Term Impact</h2>
      <div className="secretary-message-container">
        <div className="secretary-image">
          <img src={secretaryImage} alt="Secretary" />
        </div>
        <div className="secretary-message-content">
          
            <p>
              After completion of the Stitching Training Program for Adolescent Girls and Widows it is expected that 40-50 % increase in income is likely to result because of project interventions.
            </p><br />
            <h4>Financial</h4>
              <p>
                Exact financial benefits and analysis will be done through impact evaluation.
              </p>
            <h4>Economic</h4>
              <p>
                The poor, economic liability would be transformed into economic assets. It can safely be assumed that the program shall enable at least 155 women/ girls (one per household) to be employed/ self-employed. Thus 155 households i.e. 1000 women/ girls should enter the main stream of the economic activity. (Average family size=4-5)
              </p>
            <h4>Social</h4>
              <p>
                The project will create a strong social and economic capital of organized communities. This will not only have implications for stronger service delivery but will also lead to comprehensive community organizations, which are essential for the development of a just and equitable society. It is expected that 300 females would be uplifted socially. Direct employment or self-employment of female will result due to interventions relating to establishment and strengthening of existing skilled businesses of producers through skills enhancement, product quality development and higher prices as a result of linkages with markets in big cities.
              </p>
        </div>
      </div>
    </section>
  );
};

export default SecretaryMessage;
