import React from 'react';

import coachingIcon from '../assets/images/ai-img4.avif';
import coachingIcon1 from '../assets/images/ai-img3.jpg';
// import qualifiedIcon from '../assets/images/student_icon.png';
// import facultyIcon from '../assets/images/teacher_icon.png';

const Legacy = () => {
  return (
    <div className="background-section">
    <section className="legacy-section">
      <h2>WHAT THE FUTURE LOOKS WITH US </h2>
      <div className="legacy-stats" >
        <div className="legacy-item">
          
          {/* <h3>10+</h3> */}
          <p>Aaradhya Foundation believes in the new woman, who is free from shackles, stereotypes and limitations imposed by negative trends in culture or tradition and we dream of a world where women and men, girls and boys co-exist in true equality.</p>
          <p>
            ❖	We celebrate who we are today.<br />
            ❖	We anticipate the changes that are yet to be.<br />
            ❖ We participate in the fight for a better tomorrow.
          </p>
        </div>
      </div>
        <img src={coachingIcon} alt="Coaching Centers Icon" className="legacy-icon" />
        <img src={coachingIcon1} alt="Coaching Centers Icon" className="legacy-icon" />
    </section>
    </div>
  );
};

export default Legacy;
