import React from 'react';

const FeeStructure = () => {
  return (
    <section className="fee-structure-section">
      <h2> PROJECT BUDGET </h2>
      <table className="fee-table" >
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Particulars </th>
            <th>Grant Requested INR </th>
          </tr>
        </thead>
        <tbody >
          <tr>
            <td>1</td>
            <td>Purchase of 60 pedal Sewing Machines @ Rs. 10000/- </td>
            <td>6,00,000 </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Purchase of Embroidery Machine @ Rs. 60000/- </td>
            <td>60,000 </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Purchase of over-lock machine @ Rs. 17000/- </td>
            <td>17,000 </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Furniture & Fixtures @ Rs. 18500/- </td>
            <td>18,500 </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Weaving Frames @ Rs. 13000/- </td>
            <td>13,000 </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Stitching Material @ Rs. 12500/- </td>
            <td>12,500 </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Designing Books, Needles, Tracing Papers & Chopping Powder @ Rs. 9000/- </td>
            <td>9,000 </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Rent for the Training Center @ Rs. 5000x 6x6 </td>
            <td>1,80,000 </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Salary of Three Instructors @ Rs. 6000 x 3 x 6 </td>
            <td>1,08,000 </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Administrative Cost </td>
            <td>1,35,000 </td>
          </tr>
          <tr>
          <td></td>
            <td><strong> Total Cost of the Project for 3 months</strong> </td>
            <td>11,53,000 </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default FeeStructure;
