import React from 'react';
import aboutImage from '../assets/images/center.webp'; // Update with your actual image name
import aboutImage1 from '../assets/images/3188.jpg';

const AboutUs = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-us-content">
        <div className="text-content">
          <h2>ABOUT US</h2>
          <p>
          Aaradhya Foundation believes that education is both the means as well as the end to a better life: the means because it empowers an individual to earn his/her livelihood and the end because it increases one's awareness on a range of issues - from healthcare to appropriate social behavior to understanding one's rights - and in the process help him/her evolve as a better citizen. Education is the most effective tool which helps to build a strong foundation; enabling them to free themselves from the vicious cycle of ignorance, poverty and disease. 
          </p>
          <p>
          Aaradhya Foundation focuses on developing the potential of Child, women and girls to drive long lasting equitable changes. We strategically emphasize on promoting quality healthcare, inclusive education, gender equitable and sustainable livelihood opportunities and disaster relief and preparedness. 
          </p>
          <p>
          Education is the key to empowering women and girls, which helps in bringing about social equality. The Girls' Education Program works to improve lives and provide opportunities for girls and women by increasing their participation in formal and alternative education systems. The program is based on innovative pilot projects and strategic partnerships that Aaradhya Foundation has forged with government-run schools and various levels of government. We work to help Girls complete primary education and access formal schools through accelerated learning methodology and provide academic support to enhance the quality of learning. We also help nurture leadership skills amongst girls and offer alternative education opportunities for women and girls who have never been enrolled or have dropped out early from school. 
          </p>
        </div>
        <div className="image-content">
          <img src={aboutImage} alt="About Us" />
          <img src={aboutImage1} alt="About Us" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
