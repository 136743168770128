import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Add this import

// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import { Button } from 'react-bootstrap';
import logo from '../assets/images/HEADER_LOGO.png';

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false); // Close the navbar on link click
  };

  const handleDonateClick = () => {
    closeMenu();
    navigate('/Donate'); // Navigate to the Donate page on click
  };

  return (
    <Navbar expand="xl" sticky="top" className="custom-navbar" expanded={isOpen}>
      <Container>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleMenu}
          aria-expanded={isOpen ? 'true' : 'false'}
          className="custom-toggler"/>
        <div className="navbar-logo" >
          <a href="/">
            <img src={logo} alt="aflogo" className="logo" />
          </a>
          <Navbar.Brand href="/" className="foundation-brand">
            AARADHYA FOUNDATION <br />
            <span className="registration-number">Regd. No. E-1440(A)</span>
          </Navbar.Brand>      
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
        
        
          <Nav className="me-auto custom-nav">
            <Nav.Link as={Link} to="/" onClick={closeMenu}>
              HOME
            </Nav.Link>

            <Nav.Link as={HashLink} smooth to="/#about" onClick={closeMenu}>
              ABOUT US
            </Nav.Link>

            {/* Our Messages Dropdown */}
            <NavDropdown title="PROJECT DETAILS" id="our-messages-dropdown">
              <NavDropdown.Item as={Link} to="/our-messages/president" onClick={closeMenu}>
                PROJECT SUMMERY
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/principal" onClick={closeMenu}>
                PROJECT DESCRIPTION
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/secretary" onClick={closeMenu}>
                IMPACT
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/aimsobjectives" onClick={closeMenu}>
                AIMS & OBJECTIVES
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/targetgroup" onClick={closeMenu}>
                ABOUT TARGET GROUP
              </NavDropdown.Item>
            </NavDropdown>

            

            {/* CSR Activities Dropdown */}
            {/* <NavDropdown title="CSR Activities" id="csr-dropdown">
              <NavDropdown.Item as={Link} to="/csr-activities/csr-activities" onClick={closeMenu}>
                CSR Activities
              </NavDropdown.Item>
              <NavDropdown.Item href={brochurePDF} target="_blank" rel="noopener noreferrer">
                Project Brochure
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <Nav.Link as={Link} to="/events" onClick={closeMenu}>
              Events
            </Nav.Link> */}

            <NavDropdown title="OUR INSTITUTE" id="csr-dropdown">
              <NavDropdown.Item as={Link} to="https://bdharneschool.aaradhyafoundation.org.in/" onClick={closeMenu}>
                B.D. HARNE ENGLISH MEDIUM SCHOOL
              </NavDropdown.Item>
            </NavDropdown>
            
            <Nav.Link as={HashLink} smooth to="/#announcementsevents" onClick={closeMenu}>
              NEWS
            </Nav.Link>
            {/* <Nav.Link as={HashLink} smooth to="/#gallery" onClick={closeMenu}>
              Gallery
            </Nav.Link> */}
            <Nav.Link as={Link} to="/contact" onClick={closeMenu}>
              CONTACTS
            </Nav.Link>
            
            <button className="cta-btnn" onClick={handleDonateClick}>
              {/* <Nav.Link as={Link} to="/Donate" onClick={closeMenu}> */}
              <strong>DONATE</strong>
              {/* </Nav.Link> */}
            </button>

          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
