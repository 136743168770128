import React from 'react';
import targetgroupImage from '../assets/images/ai-img8.jpg'; // Update the path to your actual image

const TargetGroup = () => {
  return (
    <section className="target-group-section">
      <h2>Description About The Target Groups</h2>
      <div className="target-group-container">
        <div className="target-group-image">
          <img src={targetgroupImage} alt="targetgroup" />
          {/* <img src={principalImage} alt="Principal" /> */}
        </div>
        <div className="target-group-content">
          
          <p>
            Most of the Adolescent Girls and Widows in our area are working as agricultural labourers under the control of landlords.   But the climate favors only limited time in a year for the agricultural operations and rest of the period they are jobless and unemployed. In that slack period, they have to move the near place in searching of work for their daily bread. Their least earnings through this seasonal work could not sufficient to meet their basic needs and essential necessities of life.
          </p>
          <p>
            The rural poor, particularly women, who do not have support are the worst sufferers due to their unemployment and irregular income as a voluntary organization, we organized for the social change.   But we are unable to provide the needed financial assistance for these poor and distressed women to start the income generation activities, even the Government Department also not willing to help the needy Trainers, because of the terms and conditions for the sanction of assistance as fixed by the apex agencies. People, who do not have anything, would not get any assistance from anybody else, even the school  going children also facing lot of problems to complete their education. But the problems are to find right donor to support these women Groups, which enable them to start the Stitching Training Program. Their activity is most suitable and the target group women.
          </p>
          <p>
            After review of their all conditions, we made conclusion to approach your esteemed agency, to support the needy women groups to take up the Stitching Training Program, which make the rural poor women groups to become self-sufficient. The project is aimed at empowering the unemployed Adolescent Girls and widows in our backward rural areas in Aurangabad district in state of Maharashtra. Through Income Generating Activities, the proposed projects are an integrated approach to train in the courses of <strong> Stitching Training Program</strong>.
          </p>
        </div>
      </div><br />

      <div className="target-group-content1">
        <h2> Need Of The Project</h2>
        <p>
            The women living in Villages of Aurangabad District is very poor. Unemployment is one of the burning issues among women, due to lack of right skills, the poor people unable to utilize the available local resources. Poor women from rural communities are forced to migrate to various places in India and work as daily laborers, construction workers, cooks, brick workers and daily wage workers. The Women physically and sexually harassed by brick owners and contractors. They are living in slums, getting very low wages. The project Adolescent Girls and widows groups are poor and illiterate away from modern changes. There is a need to bring awareness and provide financial responsibilities making women become conversant with the accounts both profit and loss useful to maintain their family, with the training activities women role will be enhanced in family and community.
        </p>
        <p>
            <strong>AARADHYA FOUNDATION</strong> recognize that Stitching Training is playing a key role in every field of life with education in cities laying emphasis on Skill Training Program, the educational institutions for girls in small cities are lagging behind.
        </p>
        <p>
            <strong>AARADHYA FOUNDATION</strong> is trying to provide a solution to this. By making Stitching Training to women, we are promoting that there be no gender discrimination but empowerment of women for a better life belonging to conservative and illiterate parents, they are not allowed due to privacy and security reasons. The other very important factor is financial constraint which makes the parents reluctant to invest too much in education believing that the girls only have to be married off. It is basis of all these factors that we are proposing the setup of this center. It will help the girls and women to increase their knowledge base and understand how to tap in to any hidden talent they may have be it cooking, housekeeping, gardening, sewing etc. Adolescent Girls and Widows in these poor families find difficult to manage families. She is deprived of nutritious food, health care and minimum education. Women are poor, illiterate, exploited and suffer from many problems, within and outside house. 

        </p>
      </div><br />

      <h2>Project Implementation System</h2>
      <div className="target-group-content1">  
        <p>
          Six training centers are going to be opened in slums of Aurangabad district in state of Maharashtra. <strong>AARADHYA FOUNDATION</strong>s committee selects eligible adolescent girls and widows from each slum and rural community for the training program for underprivileged women, widows and young unmarried girls.
          </p><br />
      {/* <ul> */}
        <h4>Project Director :</h4>
        <p>
          He is responsible for the proper coordination of the program activities and accountable for the Financial Transactions and Project management.
        </p><br />

        <h4>Training Instructors :</h4>
        <p>
          Three Qualified and with 5 Years minimum experience instructors will be appointed to train the trainees in Stitching, Embroidery for a period of Six Months.
        </p><br />

        <h4>Center :</h4>
        <p>
          We shall hire a building for the Training center on rent.   Training Center provides Stitching and Embroidery Skills to 300 Women each year. These women will be selected by slum self helping groups. Training will be provided Stitching Skills Training both in stitching and embroidery besides health, finances and family management training. 
        </p><br />

        <h4>Center Time Table :</h4>
        <p>
        Training center will operate from Monday to Saturday. Center will open from 9.30 Morning to 5.30 in the evening with regular breaks for snacks and lunch. In production phase, the project supervisor and instructors will get orders for clothes and take finished garments. The amount which is gained in the center will be used for the center. The income will be paid to the trainees, center maintenance and unforeseen expenses.   When Trainees complete their training, the organization will help beneficiaries to put up tailor units in their slums. The Organization proposes to start Stitching and Embroidery Training center at Aurangabad covering 15 Villages and Slums. The rural poor Adolescent Girls and Widows, who have Basic education, will be selected for the training in Stitching, so as to enable them to acquire a suitable job in any of the private concern and earn their livelihood as well as supporting their families and dependents. The beneficiaries are identified from families belonging to S.C.’s and S.T.’S pertaining to the marginalized and depressed sections. So, the organization has planned a Stitching Training. Undoubtedly after the completion of Stitching Training, the trainees will be able to get a good job so as to improve their living standards and also to support their families and dependents. This project will surely bring light into their lives for sustainable development.
        </p>
      {/* </ul> */}
      </div><br />

      <h2>Outcome Of The Project</h2>
      <div className="target-group-content2">  
        <ol>❖  After the completion of the Training, trained Adolescent Girls and Widows will get the Jobs in Private Industries. </ol>
        <ol>❖	Some of the Adolescent Girls and Widows will start their own Stitching Shops in their villages. </ol>
        <ol>❖	The Society will help the Adolescent Girls and Widows to establish their own Stitching Shops in their villages. </ol>
        <ol>❖	The improvement of the skills of the Adolescent Girls and Widows, they will mould themselves into assets of the community. </ol>
        <ol>❖	The self-confidence will improve among them through improvement of their living standards. </ol>
        <ol>❖	Moral support for the parents, caretakers and trained volunteers should be placed in village levels that will enhance the level of the Unemployment. </ol>
        <ol>❖	Opportunities are provided to the Rural Poor Adolescent Girls and Widows from the stage of the planning to practicability. </ol>
      </div>

    </section>
  );
};

export default TargetGroup;
