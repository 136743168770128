import React from 'react';
import presidentImage1 from '../assets/images/ai-img8.jpg'; // Update the path to your actual image
import presidentImage from '../assets/images/ai-img1.avif';

const PresidentMessages = () => {
  return (
    <section className="president-message-section">
      <h2>Project Summary</h2>
      <div className="president-message-container">
        <div className="president-image">
          <img src={presidentImage} alt="President" />
          <img src={presidentImage1} alt="President" />
        </div>
        <div className="president-message-content">
            <p>
              The target group Adolescent Girls and Widows are drawn from the lower strait of the society where influenced people are dominating and ruling them since the ages together. Poverty is the main drawback found in almost all the families, where the unemployment and seasonal income do not satisfying their livelihood requirements. Sometimes they are migrating to some other places in searching of work for their daily bread. Their deriving income do not used for the production purpose but the consumption requirements fulfilled with these meager income by the large size families. Usually, population rate in rural and tribal areas is growing very fast. 
            </p>
            <p>
              As literacy and ignorance in that areas are predominant factors, which always suppressed them and stunting their development in all respects. Even family planning measures are also not accepted by them due to their illiteracy, ignorance and superstitions conditions. They always depend on the herbal medicines and crude treatments as suggested by un-qualified persons, whose profession is earning income by treating them with herbal medicines and solutions prepared with the local available plants and herbs. Sometimes crude treatments may react and create further complications. This is also one of the causes to disturb the normal life of the rural poor, added to this, the natural wealth also destructing by these ignored people either for their needs or as ordered by the influenced people. These deforestation activities have creating lot of disturbances in the present environment conditions and increasing pollution effects. The worst sufferers from all the factors are only the rural and tribal people. Children of these areas also not promoted for education as the parents do not know the value of education and its great significance in the modern advancement. They simply following certain caste principles and shouting in the darkness as a fly in the ocean.
            </p>
            <p>
              Their development is almost holed by their masters and treating them as slaves or bonded labourers. As the areas are remote in nature, no frequent transport facilities are available to reach these places when the need arise Sometimes, they use bullock carts to reach headquarters for emergency purpose, which given on their request. The target group conditions in respect of social, economical, health, hygiene, sanitation and education etc., are much lower and exhibiting the scene of primitive stage, their continuous physical work to reach the stage of development not producing desired result, because of lacking awareness. Criteria for the empowerment of these ignored people not planned by any Department due to heavy work load and political influence interference of local poor people in the initiation of any development measures also lower and meager. Health professionals appointed by the Government not interested to stay as these villages and their regular visiting also not possible due to non-availability of transport facilities. In view of the above facts the organization proposed to conduct <strong> Stitching Training Program </strong> for Adolescent Girls and Widows who are in difficult circumstances in rural areas in Aurangabad District.
            </p>
        </div>
      </div>
    </section>
  );
};

export default PresidentMessages;
